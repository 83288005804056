import React, {Component} from 'react';
import {Button, Col, Form, Icon, Input, notification, Popconfirm, Row, Select, Table} from 'antd';
import {AuthenticatedLayout} from "../../../layouts";
import {Container} from "../../../components";
import {ShowErrorNotification, ShowValidationErrorNotification} from '../../../utils';
import CreateModal from "./CreateModal";
import EditModal from "./EditModal";
import data from '../../../data';

let handleDelete;
let handleEnable;
let handleDisable;

class UsersPage extends Component {
    state = {
        filter: {enabled: 'active'}
    };

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        handleDelete = this.handleDelete = this.handleDelete.bind(this);
        handleEnable = this.handleEnable = this.handleEnable.bind(this);
        handleDisable = this.handleDisable = this.handleDisable.bind(this);
        this.openCreateModal = this.openCreateModal.bind(this);
        this.closeCreateModal = this.closeCreateModal.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.renderSearchForm = this.renderSearchForm.bind(this);
    }

    loadData(filter) {
        let enabled = undefined;
        if (filter?.enabled === 'active') {
            enabled = true;
        } else if (filter?.enabled === 'passive') {
            enabled = false;
        }
        this.setState({loading: true});
        data.allUsers({...filter, enabled})
            .then(users => this.setState({users, filter}))
            .catch(err => ShowErrorNotification('Kullanıcılar alınamadı', err))
            .finally(() => this.setState({loading: false}));
    }

    handleSearch(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification();
            }
            this.loadData(values);
        });
    }

    handleDelete(record) {
        this.setState({loading: true});
        data.deleteUser(record.id)
            .then(() => notification.success({
                message: 'Kullanıcı silindi'
            }))
            .catch(err => ShowErrorNotification('Kullanıcı silinemedi', err))
            .finally(() => this.loadData(this.state.filter));
    }

    handleEnable(record) {
        this.setState({loading: true});
        data.enableUser(record.id)
            .then(() => notification.success({
                message: 'Kullanıcı gösterildi'
            }))
            .catch(err => ShowErrorNotification('Kullanıcı gösterilemedi', err))
            .finally(() => this.loadData(this.state.filter));
    }

    handleDisable(record) {
        this.setState({loading: true});
        data.disableUser(record.id)
            .then(() => notification.success({
                message: 'Kullanıcı gizlendi'
            }))
            .catch(err => ShowErrorNotification('Kullanıcı gizlenemedi', err))
            .finally(() => this.loadData(this.state.filter));
    }

    openCreateModal() {
        this.setState({createModalVisible: true});
    }

    closeCreateModal(record) {
        this.setState({createModalVisible: false});
        if (record) this.loadData(this.state.filter);
    }

    openEditModal(record) {
        this.setState({record, editModalVisible: true});
    }

    closeEditModal(record) {
        this.setState({record: undefined, editModalVisible: false});
        if (record) this.loadData(this.state.filter);
    }

    renderSearchForm() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSearch}>
                <Row type="flex" gutter={16}>
                    <Col>
                        <Form.Item label="Ad">
                            {getFieldDecorator('name')(
                                <Input autoFocus prefix={<Icon type="search"/>}/>
                            )}
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="E-posta Adresi">
                            {getFieldDecorator('email')(<Input prefix={<Icon type="mail"/>}/>)}
                        </Form.Item>
                    </Col>
                    <Col style={{width: 120}}>
                        <Form.Item label="Durum">
                            {getFieldDecorator('enabled', {
                                initialValue: this.state.filter.enabled
                            })(
                                <Select placeholder="Hepsi">
                                    <Select.Option key="active">Aktif</Select.Option>
                                    <Select.Option key="passive">Pasif</Select.Option>
                                    <Select.Option key="all">Hepsi</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    type="flex"
                    gutter={[8,8]}
                    style={{marginBottom: 8}}
                >
                    <Col>
                        <Button
                            type="primary"
                            htmlType="submit"
                            icon="search"
                            loading={this.state.loading}
                        >
                            Ara
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            icon="plus"
                            onClick={this.openCreateModal}
                        >
                            Yeni Kullanıcı
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    componentDidMount() {
        this.loadData(this.state.filter);
    }

    render() {
        return (
            <AuthenticatedLayout>
                <Container>
                    <div className="page">
                        <h1 className="page-header">Kullanıcılar</h1>
                        {this.renderSearchForm()}
                        <Table
                            rowKey="id"
                            loading={this.state.loading}
                            columns={columns}
                            dataSource={this.state.users}
                            pagination={{style: {marginRight: 16}}}
                            onRow={record => ({
                                style: {cursor: 'pointer'},
                                onClick: () => this.openEditModal(record)
                            })}
                        />
                        <CreateModal
                            visible={this.state.createModalVisible}
                            onClose={this.closeCreateModal}
                        />
                        <EditModal
                            visible={this.state.editModalVisible}
                            record={this.state.record}
                            onClose={this.closeEditModal}
                        />
                    </div>
                </Container>
            </AuthenticatedLayout>
        );
    }
}

const columns = [{
    dataIndex: 'enabled',
    key: 'enabled',
    width: 16,
    render: (text, record) => (
        <Icon
            type={record.enabled ? "check-circle" : "close-circle"}
            style={{color: record.enabled && record.activated ? 'green' : record.enabled ? 'orange' : 'red'}}
        />
    )
}, {
    title: 'Ad',
    dataIndex: 'name',
    key: 'name'
}, {
    title: 'E-posta Adresi',
    dataIndex: 'email',
    key: 'email'
}, {
    title: 'Telefon Numarası',
    dataIndex: 'phone_number',
    key: 'phone_number'
}, {
    render: (text, record) => (
        <Row type="flex" gutter={[4,4]} justify="end">
            <Col>
                {record.enabled ? (
                    <Button
                        icon="eye-invisible"
                        onClick={e => {
                            e.stopPropagation();
                            handleDisable(record);
                        }}
                    >
                        Gizle
                    </Button>
                ) : (
                    <Button
                        icon="eye"
                        onClick={e => {
                            e.stopPropagation();
                            handleEnable(record);
                        }}
                    >
                        Göster
                    </Button>
                )}
            </Col>
            <Col>
                <Popconfirm
                    title={(
                        <div>
                            <h3>DİKKAT!</h3>
                            <p>Kullanıcıyı silmek üzeresiniz ve bu işlemin geri dönüşü olmayacaktır.</p>
                            <p>Devam etmek istediğinize emin misiniz？</p>
                        </div>
                    )}
                    okText="Evet"
                    onConfirm={e => {
                        e.stopPropagation();
                        handleDelete(record);
                    }}
                    cancelText="Hayır"
                    onCancel={e => e.stopPropagation()}
                >
                    <Button
                        type="danger"
                        icon="delete"
                        onClick={e => e.stopPropagation()}>
                        Sil
                    </Button>
                </Popconfirm>
            </Col>
        </Row>
    )
}];


export default Form.create()(UsersPage);
