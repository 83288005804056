import React, {Component} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class WysiwygInput extends Component {
    state = {
        value: ''
    };

    modules = {
        toolbar: [
            // [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            [{color: []}, {background: []}],
            ['clean']
        ],
    };

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image',
        'color', 'background'
    ];

    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
        this.loadData = this.loadData.bind(this);
        this.handleChange = this.handleChange.bind(this)
    }

    loadData() {
        if (this.state.value !== this.props.value) {
            this.setState({value: this.props.value}, () => {
                this.editorRef.current.setEditorContents(this.editorRef.current.getEditor(), this.state.value);
            });
        }
    }

    handleChange(content, delta, source, editor) {
        this.setState({value: content}, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.value);
            }
        });
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.loadData();
        }
    }

    render() {
        return (
            <ReactQuill
                ref={this.editorRef}
                theme="snow"
                modules={this.modules}
                formats={this.formats}
                onChange={this.handleChange}
            />
        )
    }
}

export default WysiwygInput;
