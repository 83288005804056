import React, {Component} from 'react';
import {Button, Card, Checkbox, Col, Form, Icon, Input, Row} from 'antd';
import {AuthenticatedLayout} from "../../layouts";
import {Container, RawHtml} from "../../components";
import {ShowErrorNotification, ShowValidationErrorNotification} from '../../utils';
import CreateModal from "./CreateModal";
import EditModal from "./EditModal";
import data from '../../data';

class PlanDetailsPage extends Component {
    state = {
        filter: {}
    };

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.handlePeriodChange = this.handlePeriodChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.openCreateModal = this.openCreateModal.bind(this);
        this.closeCreateModal = this.closeCreateModal.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
    }

    loadData() {
        this.setState({
            loading: true,
            plan: undefined,
            details: undefined
        });
        const {id: plan_id} = this.props.match.params;
        Promise.all([
            data.getPlan(plan_id),
            data.allPlanDetails({plan_id})
        ])
            .then(([plan, details]) => this.setState({plan, details}))
            .catch(err => ShowErrorNotification('Plan detayları alınamadı', err))
            .finally(() => this.setState({loading: false}));
    }

    handlePeriodChange(period_id) {
        this.setState({period_id}, this.loadData);
    }

    handleSearch(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification();
            }
            this.setState({filter: values}, this.loadData);
        });
    }

    openCreateModal() {
        this.setState({createModalVisible: true});
    }

    closeCreateModal(record) {
        this.setState({createModalVisible: false});
        if (record) this.loadData();
    }

    openEditModal(record) {
        this.setState({record, editModalVisible: true});
    }

    closeEditModal(record) {
        this.setState({record: undefined, editModalVisible: false});
        if (record) this.loadData();
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <React.Fragment>
                    <Icon spin type="sync" style={{marginRight: 5}}/>
                    Lütfen bekleyin...
                </React.Fragment>
            )
        }
        const {getFieldDecorator} = this.props.form;
        const {plan: record} = this.state;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item label="Ad (Türkçe)">
                    {getFieldDecorator('name_tr', {
                        initialValue: record?.name_tr,
                        rules: [{required: true, message: 'Türkçe adı girilmemiş'}]
                    })(<Input autoFocus />)}
                </Form.Item>
                <Form.Item label="Ad (Almanca)">
                    {getFieldDecorator('name_de', {
                        initialValue: record?.name_de,
                        rules: [{required: true, message: 'Almanca adı girilmemiş'}]
                    })(<Input />)}
                </Form.Item>
                {getFieldDecorator('enabled', {
                    initialValue: record?.enabled,
                    valuePropName: 'checked',
                })(<Checkbox>Aktif</Checkbox>)}
                {getFieldDecorator('published', {
                    initialValue: record?.published,
                    valuePropName: 'checked',
                })(<Checkbox>Yayında</Checkbox>)}
                <br/>
                <br/>
                <br/>
                <Form.Item>
                    <Button type="primary" htmlType="submit" icon="check" loading={this.state.loading}>Kaydet</Button>
                    <a
                        href={record?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{marginLeft: 8}}
                    >
                        <Button icon="file-text" disabled={!record?.url}>Göster</Button>
                    </a>
                </Form.Item>
            </Form>
        );
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <AuthenticatedLayout>
                <Container>
                    <div className="page">
                        <h1 className="page-header">Ders Planı</h1>
                        <br/>
                        {this.renderForm()}
                        <br/>
                        {this.state.plan && (
                            <React.Fragment>
                                <h1 className="page-header">Bu Ders Planının Haftaları</h1>
                                <br/>
                                <Row
                                    type="flex"
                                    gutter={[16,16]}
                                    style={{marginBottom: 0}}
                                >
                                    <Col>
                                        <Button
                                            type="primary"
                                            icon="plus"
                                            onClick={this.openCreateModal}
                                        >
                                            Yeni Hafta
                                        </Button>
                                    </Col>
                                </Row>
                                {/*<Table*/}
                                {/*    rowKey="id"*/}
                                {/*    loading={this.state.loading}*/}
                                {/*    columns={columns}*/}
                                {/*    dataSource={this.state.details}*/}
                                {/*    pagination={false}*/}
                                {/*    onRow={record => ({*/}
                                {/*        style: {cursor: 'pointer'},*/}
                                {/*        onClick: () => this.openEditModal(record)*/}
                                {/*    })}*/}
                                {/*/>*/}
                                <Row type="flex" gutter={[16,16]}>
                                    {this.state.details.map(detail => (
                                        <Col key={detail.id}>
                                            <Card
                                                size="small"
                                                title={`${detail.week_number}. Hafta`}
                                                onClick={() => this.openEditModal(detail)}
                                                style={{
                                                    width: 280,
                                                    height: 200,
                                                    overflow: 'hidden',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <RawHtml>{detail.topics}</RawHtml>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                                <CreateModal
                                    planId={this.state.plan?.id}
                                    visible={this.state.createModalVisible}
                                    onClose={this.closeCreateModal}
                                />
                                <EditModal
                                    record={this.state.record}
                                    visible={this.state.editModalVisible}
                                    onClose={this.closeEditModal}
                                />
                            </React.Fragment>
                        )}
                    </div>
                </Container>
            </AuthenticatedLayout>
        );
    }
}

// const columns = [{
//     dataIndex: 'enabled',
//     key: 'enabled',
//     width: 16,
//     render: (text, record) => (
//         <Icon type={record.enabled ? "check-circle" : "close-circle"}
//               style={{color: record.enabled ? "green" : "red"}}/>
//     )
// }, {
//     title: 'Hafta No',
//     width: 100,
//     dataIndex: 'week_number',
//     key: 'week_number'
// }, {
//     title: 'Konu ve Üniteler',
//     dataIndex: 'topics',
//     key: 'topics',
//     render: (text, record) => <RawHtml>{record.topics}</RawHtml>
// }];

export default Form.create()(PlanDetailsPage);
