import React, {Component} from 'react';
import {Link, Redirect, withRouter} from "react-router-dom";
import {Button, Form, Input, notification} from 'antd';
import {DefaultLayout} from "../../layouts";
import {Container} from "../../components";
import {Authentication, ShowErrorNotification} from '../../utils';
import QueryString from 'query-string';

class LoginPage extends Component {

    state = {
        loggingIn: false,
        loggedIn: false
    };

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderLoginForm = this.renderLoginForm.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return ShowErrorNotification(
                    'Hatalı kullanıcı veya şifre',
                    'Lütfen e-posta adresinizi ve şifrenizi kontrol edip tekrar deneyin.'
                );
            }
            const {email, password} = values;
            this.setState({loggingIn: true});
            Authentication.login(email, password)
                .then(user => {
                    console.log("User logged in.", user);
                    notification.success({
                        message: "Kullanıcı girişi yapıldı",
                        description: "Güvenliğiniz için çıkış yapmayı unutmayın."
                    });
                    const {history, location} = this.props;
                    const {returnUrl} = QueryString.parse(location.search);
                    setTimeout(() => {
                        if (returnUrl !== undefined) {
                            console.log("Returning to url.", returnUrl);
                            history.push(returnUrl);
                        } else {
                            history.push("/");
                        }
                    }, 100);
                })
                .catch(err => {
                    if (err.code === 'UserNotConfirmedException') {
                        ShowErrorNotification('Kullanıcı hesabı etkinleştirilmemiş', err);
                    } else if (err.code === 'PasswordResetRequiredException') {
                        ShowErrorNotification('Şifre değişikliği gerekiyor', err);
                    } else if (err.code === 'NotAuthorizedException' || err.code === 'UserNotFoundException') {
                        ShowErrorNotification('Hatalı kullanıcı adı veya şifre', err);
                    } else {
                        ShowErrorNotification('Beklenmeyen bir hata oluştu', err);
                    }
                })
                .finally(() => this.setState({loggingIn: false}));
        });
    }

    renderLoginForm() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item label='E-posta Adresi'>
                    {getFieldDecorator('email', {
                        rules: [
                            { required: true, message: 'E-posta adresi girilmemiş!' },
                            { type: 'email', message: 'Geçerli bir e-posta adresi değil!' }
                        ],
                    })(<Input/>)}
                </Form.Item>
                <Form.Item label='Şifre'>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Şifre girilmemiş!' }],
                    })(<Input.Password/>)}
                </Form.Item>
                <br/>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon="login"
                    className="login-form-button"
                    loading={this.state.loggingIn}
                >
                    Giriş yap
                </Button>
                <br/>
                <br/>
                <br/>
                <div style={{textAlign: 'right'}}>
                    <Link className="login-form-forgot" to='/forgot-password'>Şifremi unuttum!</Link>
                </div>
            </Form>
        );
    }

    componentDidMount() {
        Authentication.getCurrentSession()
            .then(user => {
                if (user) this.setState({loggedIn: true});
            });
    }

    render() {
        if (this.state.loggedIn) {
            return <Redirect to="/"/>
        }
        return (
            <DefaultLayout>
                <Container size="small">
                    <div className="page">
                        <h1 className="page-header">Kullanıcı Girişi</h1>
                        {this.renderLoginForm()}
                    </div>
                </Container>
            </DefaultLayout>
        );
    }
}

export default Form.create()(withRouter(LoginPage));
