import React, {Component} from 'react';
import {Button, Col, Form, Icon, Input, notification, Radio, Row} from 'antd';
import {AuthenticatedLayout} from "../../../layouts";
import {Container, PeriodSelect} from "../../../components";
import data from '../../../data';
import {ShowErrorNotification, ShowValidationErrorNotification} from "../../../utils";

class SettingsPage extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    loadData() {
        this.setState({loading: true});
        data.allSettings()
            .then(settings => {
                const values = settings.reduce((result, current) => {
                    result[current.key] = current.value;
                    return result;
                }, {});
                // this.props.form.setFieldsValue(values);
                this.setState({record: values});
            })
            .catch(err => ShowErrorNotification('Ayarlar alınamadı', err))
            .finally(() => this.setState({loading: false}));
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification();
            }
            this.setState({loading: true});
            const settings = Object.keys(values).reduce((result, key) => {
                result.push({key, value: values[key]});
                return result;
            }, []);
            data.updateSettings(settings)
                .then(() => {
                    notification.success({
                        message: 'Ayarlar kaydedildi.'
                    });
                    this.loadData();
                })
                .catch(err => ShowErrorNotification('Ayarlar kaydedilemedi', err))
                .finally(() => this.setState({loading: false}));
        });
    };

    renderForm() {
        if (this.state.loading) {
            return (
                <React.Fragment>
                    <Icon spin type="sync" style={{marginRight: 5}}/>
                    Lütfen bekleyin...
                </React.Fragment>
            )
        }
        const {getFieldDecorator} = this.props.form;
        const {record} = this.state;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Row gutter={16}>
                    <Col sm={8} lg={4}>
                        <Form.Item label="Geçerli Eğitim-Öğretim Yılı">
                            {getFieldDecorator('CURRENT_PERIOD', {
                                initialValue: record?.CURRENT_PERIOD,
                                rules: [{required: true, message: 'Yıl seçilmemiş'}]
                            })(<PeriodSelect autoFocus />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col sm={12}>
                        <Form.Item label="Tanıtım Videosu Youtube Video Id'si">
                            {getFieldDecorator('REGISTRATION_VIDEO', {
                                initialValue: record?.REGISTRATION_VIDEO
                            })(<Input />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col sm={12}>
                        <Form.Item label="Okullara formları gönder">
                            {getFieldDecorator('EMAIL_SCHOOLS', {
                                initialValue: record?.EMAIL_SCHOOLS || 'false'
                            })(
                                <Radio.Group>
                                    <Radio value="true">Evet</Radio>
                                    <Radio value="false">Hayır</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col sm={12}>
                        <Form.Item label="E-posta Adresleri (email adresi olmayan okulların yerine)">
                            {getFieldDecorator('FALLBACK_EMAILS', {
                                initialValue: record?.FALLBACK_EMAILS
                            })(<Input.TextArea rows={4} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col sm={12}>
                        <Form.Item label="E-posta Adresleri (form kopyaları için)">
                            {getFieldDecorator('BACKUP_EMAILS', {
                                initialValue: record?.BACKUP_EMAILS
                            })(<Input.TextArea rows={4} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <br/>
                <Button
                    key="submit"
                    type="primary"
                    icon={this.state.loading ? 'loading' : 'check'}
                    onClick={this.handleSubmit}
                >
                    Kaydet
                </Button>
            </Form>
        )
    }
    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <AuthenticatedLayout>
                <Container>
                    <div className="page">
                        <h1 className="page-header">Sistem Ayarları</h1>
                        <br/>
                        {this.renderForm()}
                    </div>
                </Container>
            </AuthenticatedLayout>
        );
    }
}

export default Form.create()(SettingsPage);
