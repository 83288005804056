import React, {Component} from 'react';
import {Button, Checkbox, Form, Input, Modal} from "antd";
import {ShowErrorNotification, ShowValidationErrorNotification} from '../../../utils';
import data from "../../../data";

class EditModal extends Component {
    state = {
        loading: false,
        visible: false,
    };

    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    showModal(record) {
        this.setState({
            record,
            loading: false,
            visible: true
        });
    };

    closeModal(record) {
        this.setState({visible: false}, () => {
            if (this.props.onClose) {
                this.props.onClose(record);
            }
        })
    };

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification();
            }
            this.setState({loading: true});
            data.updateSchool(this.state.record.id, values)
                .then(this.closeModal)
                .catch(err => ShowErrorNotification('Okul kaydedilemedi', err))
                .finally(() => this.setState({loading: false}));
        });
    };

    handleCancel() {
        this.closeModal();
    };

    renderForm() {
        const {getFieldDecorator} = this.props.form;
        const {record} = this.state;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item label="Ad">
                    {getFieldDecorator('name', {
                        initialValue: record?.name,
                        rules: [{required: true, message: 'Ad girilmemiş'}]
                    })(<Input autoFocus />)}
                </Form.Item>
                <Form.Item label="E-posta Adresi">
                    {getFieldDecorator('email', {
                        initialValue: record?.email,
                        rules: [
                            {type: 'email', message: 'Geçerli e-posta adresi değil'}
                        ]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="E-posta Adresi (2)">
                    {getFieldDecorator('email2', {
                        initialValue: record?.email2,
                        rules: [
                            {type: 'email', message: 'Geçerli e-posta adresi değil'}
                        ]
                    })(<Input />)}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('enabled', {
                        initialValue: record?.enabled,
                        valuePropName: 'checked',
                    })(<Checkbox>Aktif</Checkbox>)}
                </Form.Item>
            </Form>
        );
    }

    componentDidMount() {
        if (this.props.visible) {
            this.showModal();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visible !== this.props.visible && this.props.visible) {
            this.showModal(this.props.record);
        }
    }

    render() {
        return (
            <Modal
                visible={this.state.visible}
                title="Okul Düzenle"
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                footer={[
                    <Button
                        key="back"
                        onClick={this.handleCancel}
                    >
                        Kapat
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        icon={this.state.loading ? 'loading' : 'check'}
                        onClick={this.handleSubmit}
                    >
                        Kaydet
                    </Button>
                ]}
                destroyOnClose={true}
            >
                {this.renderForm()}
            </Modal>
        );
    }
}

export default Form.create()(EditModal);
