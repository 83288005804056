import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Button, Col, Form, Icon, Input, notification, Row} from 'antd';
import {AuthenticatedLayout} from "../../layouts";
import {Container} from "../../components";
import {Authentication, ShowErrorNotification, ShowValidationErrorNotification} from "../../utils";

class ProfilePage extends Component {
    state = {
        loading: true
    };

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
        this.openViewModal = this.openViewModal.bind(this);
        this.closeViewModal = this.closeViewModal.bind(this);
        this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
        this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
        this.validateToNextPassword = this.validateToNextPassword.bind(this);
        this.renderUpdateForm = this.renderUpdateForm.bind(this);
    }

    loadData() {
        this.setState({loading: true});
        Authentication.getCurrentUser()
            .then(user => this.setState({user}))
            .catch(err => ShowErrorNotification('Kullanıcı bilgileri alınamadı', err))
            .finally(() => this.setState({loading: false}));
    }

    handleUpdateProfile(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification();
            }
            this.setState({submitting: true});
            const {password, newPassword, newPasswordConfirm, ...attributes} = values;
            const operations = [Authentication.updateProfile(attributes)];
            if (newPassword) {
                operations.push(Authentication.changePassword(password, newPassword));
            }
            Promise.all(operations)
                .then(async () => {
                    notification.success({
                        message: 'Profil bilgileriniz güncellendi',
                        description: 'Bu sayfa şimdi tekrar yüklenilecek. Lütfen bekleyin.'
                    });
                    await Authentication.getCurrentUser(true);
                    window.location.reload();
                })
                .catch(err => ShowErrorNotification('Profil bilgileriniz güncellenemedi', err))
                .finally(() => this.setState({submitting: false}));
        });
    }

    openViewModal(record) {
        this.setState({modalVisible: true, record});
    }

    closeViewModal() {
        this.setState({modalVisible: false});
    }

    handleConfirmBlur(e) {
        this.setState({confirmDirty: this.state.confirmDirty || !!e.target.value});
    };

    compareToFirstPassword(rule, value, callback) {
        if (value && value !== this.props.form.getFieldValue('newPassword')) {
            callback('Girilen şifreler aynı değil');
        } else {
            callback();
        }
    };

    validatePhoneNumber(rule, value, callback) {
        if (value && !value.match(/^\+49[\d]{10}$/)) {
            callback('Geçersiz telefon numarası');
        } else {
            callback();
        }
    };

    validateToNextPassword(rule, value, callback) {
        if (value && this.state.confirmDirty) {
            this.props.form.validateFields(['newPasswordConfirm'], { force: true });
        }
        callback();
    };

    renderUpdateForm() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleUpdateProfile}>
                <Row gutter={24}>
                    <Col sm={12} md={8}>
                        <Form.Item label="Ad, Soyad">
                            {getFieldDecorator('name', {
                                initialValue: this.state.user?.name,
                                rules: [{required: true, message: 'Ad, soyad girilmemiş'}]
                            })(<Input autoFocus/>)}
                        </Form.Item>
                    </Col>
                    <Col sm={12} md={8}>
                        <Form.Item label="Email Adresi">
                            <Input disabled value={this.state.user?.email}/>
                        </Form.Item>
                    </Col>
                    <Col sm={12} md={8}>
                        <Form.Item label="Telefon Numarası">
                            {getFieldDecorator('phone_number', {
                                initialValue: this.state.user?.phone_number || '',
                                rules: [{validator: this.validatePhoneNumber}]
                            })(<Input placeholder={'+49XXXXXXXXXX'}/>)}
                        </Form.Item>
                    </Col>
                    <Col sm={12} md={8}>
                        <Form.Item label="Şifre">
                            {getFieldDecorator('password')(<Input.Password />)}
                        </Form.Item>
                    </Col>
                    <Col sm={12} md={8}>
                        <Form.Item label="Yeni Şifre">
                            {getFieldDecorator('newPassword', {
                                rules: [
                                    {min: 8, message: 'Şifre en az 8 karakter olmalı'},
                                    {validator: this.validateToNextPassword}
                                ],
                            })(<Input.Password />)}
                        </Form.Item>
                    </Col>
                    <Col sm={12} md={8}>
                        <Form.Item label="Yeni Şifre (tekrar)">
                            {getFieldDecorator('newPasswordConfirm', {
                                rules: [
                                    {validator: this.compareToFirstPassword}
                                ],
                            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <br/>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon="check"
                    loading={this.state.submitting}
                >
                    Kaydet
                </Button>
            </Form>
        );
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <AuthenticatedLayout>
                <Container>
                    <div className="page">
                        <h1 className="page-header">
                            Hesabım
                        </h1>
                        <br/>
                        {this.state.loading ? (
                            <React.Fragment>
                                <Icon spin type="sync" style={{marginRight: 5}}/>
                                Lütfen bekleyin...
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {this.renderUpdateForm()}
                            </React.Fragment>
                        )}
                    </div>
                </Container>
            </AuthenticatedLayout>
        );
    }
}

export default Form.create()(withRouter(ProfilePage));
