import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Button, Result} from "antd";
import {DefaultLayout} from "../../layouts";
import {Container} from "../../components";

class NotFoundPage extends Component {

    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack(){
        this.props.history.goBack();
    }

    render() {
        return (
            <DefaultLayout>
                <Container>
                    <div className="page">
                        <Result
                            status="404"
                            title="404"
                            subTitle="Üzgünüz, aradığınız sayfa bulunamadı."
                            extra={<Button type="primary" onClick={this.goBack}>Önceki sayfaya dön</Button>}
                        />
                    </div>
                </Container>
            </DefaultLayout>
        );
    }
}

export default withRouter(NotFoundPage);
