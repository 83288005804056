import React, {Component} from 'react';
import './Container.css';

class Container extends Component {
    render() {
        const {size, color} = this.props;
        return (
            <div className={`${color} container-wrapper`}>
                <div className={`${size ? `${size}-container` : 'container'}`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Container;
