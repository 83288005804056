import React, {Component} from 'react';
import {Button, Checkbox, Form, Icon, InputNumber, Modal, Tabs} from "antd";
import {WysiwygInput} from "../../components";
import {ShowErrorNotification, ShowValidationErrorNotification} from '../../utils';
import data from "../../data";

class EditModal extends Component {
    state = {
        loading: false,
        visible: false,
    };

    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    showModal(record) {
        this.setState({record, loading: false, visible: true});
        // data.getPlanDetail(record.id)
        //     .then(record => this.setState({record}))
        //     .catch(err => ShowErrorNotification('Plan detayları alınamadı', err))
        //     .finally(() => this.setState({loading: false}));
    };

    closeModal(record) {
        this.setState({visible: false}, () => {
            if (this.props.onClose) {
                this.props.onClose(record);
            }
        })
    };

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification();
            }
            this.setState({loading: true});
            data.updatePlanDetail(this.state.record.id, values)
                .then(this.closeModal)
                .catch(err => ShowErrorNotification('Hafta kaydedilemedi', err))
                .finally(() => this.setState({loading: false}));
        });
    };

    handleCancel() {
        this.closeModal();
    };

    renderForm() {
        if (!this.state.record) {
            return (
                <React.Fragment>
                    <Icon spin type="sync" style={{marginRight: 5}}/>
                    Lütfen bekleyin...
                </React.Fragment>
            );
        }
        const {getFieldDecorator} = this.props.form;
        const {record} = this.state;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Tabs type="card">
                    <Tabs.TabPane key="topics" tab="Ünite ve Konular">
                        <Form.Item label="Hafta No">
                            {getFieldDecorator('week_number', {
                                initialValue: record?.week_number,
                                rules: [{required: true, message: 'Hafta no girilmemiş'}]
                            })(<InputNumber autoFocus min={1} max={52}/>)}
                        </Form.Item>
                        <Form.Item label="Ünite ve Konular">
                            {getFieldDecorator('topics', {
                                initialValue: record?.topics,
                                rules: [{required: true, message: 'Ünite ve konular girilmemiş'}]
                            })(<WysiwygInput />)}
                        </Form.Item>
                        {getFieldDecorator('enabled', {
                            initialValue: record?.enabled,
                            valuePropName: 'checked',
                        })(<Checkbox>Aktif</Checkbox>)}
                    </Tabs.TabPane>
                    <Tabs.TabPane key="achievements" tab="Amaçlar ve Kazanımlar">
                        <Form.Item label="Amaçlar ve Kazanımlar">
                            {getFieldDecorator('achievements', {
                                initialValue: record?.achievements
                            })(<WysiwygInput />)}
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="activities" tab="Etkinlikler">
                        <Form.Item label="Etkinlikler">
                            {getFieldDecorator('activities', {
                                initialValue: record?.activities
                            })(<WysiwygInput />)}
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="tools" tab="Araçlar">
                        <Form.Item label="Araçlar">
                            {getFieldDecorator('tools', {
                                initialValue: record?.tools
                            })(<WysiwygInput />)}
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="methods" tab="Yöntem ve Teknikler">
                        <Form.Item label="Yöntem ve Teknikler">
                            {getFieldDecorator('methods', {
                                initialValue: record?.methods
                            })(<WysiwygInput />)}
                        </Form.Item>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="assessment" tab="Değerlendirme">
                        <Form.Item label="Değerlendirme">
                            {getFieldDecorator('assessment', {
                                initialValue: record?.assessment
                            })(<WysiwygInput />)}
                        </Form.Item>
                    </Tabs.TabPane>
                </Tabs>
            </Form>
        );
    }

    componentDidMount() {
        if (this.props.visible) {
            this.showModal(this.props.record);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visible !== this.props.visible && this.props.visible) {
            this.showModal(this.props.record);
        }
    }

    render() {
        return (
            <Modal
                width={800}
                visible={this.state.visible}
                title="Hafta Düzenle"
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                footer={[
                    <Button
                        key="back"
                        onClick={this.handleCancel}
                    >
                        Kapat
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        icon={this.state.loading ? 'loading' : 'check'}
                        onClick={this.handleSubmit}
                    >
                        Kaydet
                    </Button>
                ]}
                destroyOnClose={true}
            >
                {this.renderForm()}
            </Modal>
        );
    }
}

export default Form.create()(EditModal);
