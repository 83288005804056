import React, {Component} from 'react';
import {Button, Col, Form, Icon, Input, Modal, notification, Popconfirm, Row, Select, Table} from 'antd';
import {AuthenticatedLayout} from "../../layouts";
import {Container, PeriodSelect} from "../../components";
import {ShowErrorNotification, ShowValidationErrorNotification} from "../../utils";
import EditModal from "./EditModal";
import data from '../../data';
import moment from 'moment';

let handleDelete;
let handleEnable;
let handleDisable;

class RegistrationsPage extends Component {
    state = {
        filter: {enabled: 'active'}
    };

    constructor(props) {
        super(props);
        this.loadSchools = this.loadSchools.bind(this);
        this.loadData = this.loadData.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.handlePeriodChange = this.handlePeriodChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        handleDelete = this.handleDelete = this.handleDelete.bind(this);
        handleEnable = this.handleEnable = this.handleEnable.bind(this);
        handleDisable = this.handleDisable = this.handleDisable.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.renderSearchForm = this.renderSearchForm.bind(this);
    }

    loadSchools() {
        data.allSchools()
            .then(schools => this.setState({schools}))
            .catch(err => ShowErrorNotification('Okullar alınamadı', err));
    }

    loadData(filter) {
        if (!this.state.period_id) {
            this.setState({registrations: undefined});
            return;
        }
        let enabled = undefined;
        if (filter?.enabled === 'active') {
            enabled = true;
        } else if (filter?.enabled === 'passive') {
            enabled = false;
        }
        this.setState({loading: true});
        data.allRegistrations({...filter, enabled, period_id: this.state.period_id})
            .then(registrations => this.setState({registrations, filter}))
            .catch(err => ShowErrorNotification('Öğrenci ders kayıtları alınamadı', err))
            .finally(() => this.setState({loading: false}));
    }

    downloadSpreadsheet() {
        if (!this.state.period_id) {
            return ShowErrorNotification('Dönem seçilmemiş', 'Lütfen dönem seçip tekrar deneyin.');
        }
        this.setState({downloading: true});
        data.downloadRegistrations(this.state.period_id)
            .then(spreadsheet => {
                Modal.confirm({
                    title: 'Öğrenci Ders Kayıtları',
                    content: (
                        <div>
                            <p>Excel dosyası hazırlandı. İndirmek için aşağıdaki 'İndir' tuşuna tıklayın.</p>
                            <br/>
                            <Col align="center">
                                <a href={spreadsheet.url} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        type="primary"
                                        size="large"
                                        icon="download"
                                    >
                                        İndir
                                    </Button>
                                </a>
                            </Col>
                        </div>
                    ),
                    okButtonProps: {hidden: true},
                    cancelText: 'Kapat',
                });
            })
            .catch(err => ShowErrorNotification('Ders kayıt başvuruları indirilemedi', err))
            .finally(() => this.setState({downloading: false}));
    }

    handlePeriodChange(period_id) {
        this.setState({period_id}, () => {
            this.loadData(this.state.filter);
        });
    }

    handleSearch(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification();
            }
            this.loadData(values);
        });
    }

    handleDelete(record) {
        this.setState({loading: true});
        data.deleteRegistration(record.id)
            .then(() => notification.success({
                message: 'Öğrenci ders kaydı silindi'
            }))
            .catch(err => ShowErrorNotification('Öğrenci ders kaydı silinemedi', err))
            .finally(() => this.loadData(this.state.filter));
    }

    handleEnable(record) {
        this.setState({loading: true});
        data.enableRegistration(record.id)
            .then(() => notification.success({
                message: 'Öğrenci ders kaydı gösterildi'
            }))
            .catch(err => ShowErrorNotification('Öğrenci ders kaydı gösterilemedi', err))
            .finally(() => this.loadData(this.state.filter));
    }

    handleDisable(record) {
        this.setState({loading: true});
        data.disableRegistration(record.id)
            .then(() => notification.success({
                message: 'Öğrenci ders kaydı gizlendi'
            }))
            .catch(err => ShowErrorNotification('Öğrenci ders kaydı gizlenemedi', err))
            .finally(() => this.loadData(this.state.filter));
    }

    openEditModal(record) {
        this.setState({record, editModalVisible: true});
    }

    closeEditModal(record) {
        this.setState({record: undefined, editModalVisible: false});
        if (record) this.loadData(this.state.filter);
    }

    renderSearchForm() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSearch}>
                <Row type="flex" gutter={16}>
                    <Col>
                        <Form.Item label="Eğitim-Öğretim Yılı">
                            <PeriodSelect
                                placeholder="Yıl seçin"
                                onChange={this.handlePeriodChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Veli Adı, Soyadı">
                            {getFieldDecorator('parent_name')(
                                <Input autoFocus prefix={<Icon type="search"/>}/>
                            )}
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Öğrenci Adı, Soyadı">
                            {getFieldDecorator('student_name')(
                                <Input prefix={<Icon type="search"/>}/>
                            )}
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Telefon Numarası">
                            {getFieldDecorator('phone')(
                                <Input prefix={<Icon type="phone"/>}/>
                            )}
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Okul Adı">
                            {getFieldDecorator('school_id')(
                                <Select
                                    style={{width: 400}}
                                    showSearch
                                    disabled={!this.state.schools}
                                    loading={!this.state.schools}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.schools?.map(school => (
                                        <Select.Option
                                            key={school.id}
                                            value={school.id}
                                        >
                                            {school.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col style={{width: 120}}>
                        <Form.Item label="Durum">
                            {getFieldDecorator('enabled', {
                                initialValue: this.state.filter.enabled
                            })(
                                <Select placeholder="Hepsi">
                                    <Select.Option key="active">Aktif</Select.Option>
                                    <Select.Option key="passive">Pasif</Select.Option>
                                    <Select.Option key="all">Hepsi</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    type="flex"
                    gutter={[8,8]}
                    style={{marginBottom: 8}}
                >
                    <Col>
                        <Button
                            type="primary"
                            htmlType="submit"
                            icon="search"
                            loading={this.state.loading}
                        >
                            Ara
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            icon="file-excel"
                            loading={this.state.downloading}
                            onClick={this.downloadSpreadsheet}
                        >
                            Excel Dosyasını İndir
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    componentDidMount() {
        this.loadSchools();
        this.loadData(this.state.filter);
    }

    render() {
        return (
            <AuthenticatedLayout>
                <Container>
                    <div className="page">
                        <h1 className="page-header">Öğrenci Ders Kayıtları</h1>
                        {this.renderSearchForm()}
                        <Table
                            rowKey="id"
                            loading={this.state.loading}
                            columns={columns}
                            dataSource={this.state.registrations}
                            pagination={{style: {marginRight: 16}}}
                            onRow={record => ({
                                style: {cursor: 'pointer'},
                                onClick: () => this.openEditModal(record)
                            })}
                        />
                        <EditModal
                            visible={this.state.editModalVisible}
                            record={this.state.record}
                            onClose={this.closeEditModal}
                        />
                    </div>
                </Container>
            </AuthenticatedLayout>
        );
    }
}

const columns = [{
    dataIndex: 'enabled',
    key: 'enabled',
    width: 16,
    render: (text, record) => (
        <Icon type={record.enabled ? "check-circle" : "close-circle"}
              style={{color: record.enabled ? "green" : "red"}}/>
    )
}, {
    title: 'Tarih',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text, record) => <span>{moment(record.created_at).format('DD/MM/YYYY')}</span>
// }, {
//     title: 'Veli Ad, Soyad',
//     dataIndex: 'parent_name',
//     key: 'parent_name'
}, {
    title: 'Öğrenci Ad, Soyad',
    dataIndex: 'student_name',
    key: 'student_name'
// }, {
//     title: 'Email',
//     dataIndex: 'email',
//     key: 'email'
// }, {
//     title: 'Telefon',
//     dataIndex: 'phone_number',
//     key: 'phone_number'
}, {
    title: 'Okul',
    dataIndex: 'school_name',
    key: 'school_name'
}, {
    title: 'Sınıf',
    dataIndex: 'class_name',
    key: 'class'
}, {
    render: (text, record) => (
        <Row type="flex" gutter={[4,4]} justify="end">
            <Col>
                {record.enabled ? (
                    <Button
                        icon="eye-invisible"
                        onClick={e => {
                            e.stopPropagation();
                            handleDisable(record);
                        }}
                    >
                        Gizle
                    </Button>
                ) : (
                    <Button
                        icon="eye"
                        onClick={e => {
                            e.stopPropagation();
                            handleEnable(record);
                        }}
                    >
                        Göster
                    </Button>
                )}
            </Col>
            <Col>
                <Popconfirm
                    title={(
                        <div>
                            <h3>DİKKAT!</h3>
                            <p>Öğrenci ders kaydını silmek üzeresiniz ve bu işlemin geri dönüşü olmayacaktır.</p>
                            <p>Devam etmek istediğinize emin misiniz？</p>
                        </div>
                    )}
                    okText="Evet"
                    onConfirm={e => {
                        e.stopPropagation();
                        handleDelete(record);
                    }}
                    cancelText="Hayır"
                    onCancel={e => e.stopPropagation()}
                >
                    <Button
                        type="danger"
                        icon="delete"
                        onClick={e => e.stopPropagation()}>
                        Sil
                    </Button>
                </Popconfirm>
            </Col>
        </Row>
    )
}];

export default Form.create()(RegistrationsPage);
