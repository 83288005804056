import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Button, Checkbox, Form, Input, Modal} from "antd";
import {ShowErrorNotification, ShowValidationErrorNotification} from "../../utils";
import data from "../../data";

class EditModal extends Component {
    state = {
        loading: false,
        visible: false,
    };

    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    showModal(record) {
        this.setState({
            record,
            loading: false,
            visible: true
        });
    };

    closeModal(record) {
        this.setState({visible: false}, () => {
            if (this.props.onClose) {
                this.props.onClose(record);
            }
        })
    };

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification();
            }
            this.setState({loading: true});
            data.updatePlan(this.state.record.id, values)
                .then(this.closeModal)
                .catch(err => ShowErrorNotification('Ders planı kaydedilemedi', err))
                .finally(() => this.setState({loading: false}));
        });
    };

    handleCancel() {
        this.closeModal();
    };

    renderForm() {
        const {getFieldDecorator} = this.props.form;
        const {record} = this.state;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item label="Ad (Türkçe)">
                    {getFieldDecorator('name_tr', {
                        initialValue: record?.name_tr,
                        rules: [{required: true, message: 'Türkçe adı girilmemiş'}]
                    })(<Input autoFocus />)}
                </Form.Item>
                <Form.Item label="Ad (Almanca)">
                    {getFieldDecorator('name_de', {
                        initialValue: record?.name_de,
                        rules: [{required: true, message: 'Almanca adı girilmemiş'}]
                    })(<Input />)}
                </Form.Item>
                {getFieldDecorator('enabled', {
                    initialValue: record?.enabled,
                    valuePropName: 'checked',
                })(<Checkbox>Aktif</Checkbox>)}
                {getFieldDecorator('published', {
                    initialValue: record?.published,
                    valuePropName: 'checked',
                })(<Checkbox>Yayında</Checkbox>)}
            </Form>
        );
    }

    componentDidMount() {
        if (this.props.visible) {
            this.showModal();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visible !== this.props.visible && this.props.visible) {
            this.showModal(this.props.record);
        }
    }

    render() {
        return (
            <Modal
                visible={this.state.visible}
                title="Ders Planı Düzenle"
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                footer={[
                    <Link
                        key="details"
                        to={`/plans/${this.state.record?.id}`}
                        style={{float: 'left'}}
                    >
                        <Button icon="search">
                            Detay
                        </Button>
                    </Link>,
                    <a
                        key="download"
                        href={this.state.record?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{float: 'left', marginLeft: 8}}
                    >
                        <Button icon="file-text" disabled={!this.state.record?.url}>Göster</Button>
                    </a>,
                    <Button
                        key="back"
                        onClick={this.handleCancel}
                    >
                        Kapat
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        icon={this.state.loading ? 'loading' : 'check'}
                        onClick={this.handleSubmit}
                    >
                        Kaydet
                    </Button>
                ]}
                destroyOnClose={true}
            >
                {this.renderForm()}
            </Modal>
        );
    }
}

export default Form.create()(withRouter(EditModal));
