import {GenericAPI} from "./generic-api-client";
import {API} from "../../utils/api";

export default GenericAPI("plans", path => {
    return {
        copy(originalId, data, params) {
            return API.post(`${path}/copy/${originalId}`, {...data, params});
        }
    }
});
