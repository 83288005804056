import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Icon, Layout, Menu, notification} from 'antd';
import {Authentication, ShowErrorNotification} from '../utils';

class AuthenticatedLayout extends React.Component {
    state = {};

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.logout = this.logout.bind(this);
    }

    handleClick(e) {
        let url = e.keyPath.reduce((result, current) => {
            result = `/${current}${result}`;
            return result;
        }, '');
        if (url) {
            this.props.history.push(url);
        }
    }

    logout() {
        Authentication.logout()
            .then(() => {
                notification.success({
                    message: 'Kullanıcı çıkışı yapıldı',
                    description: 'Tekrar giriş yapabilir veya bu sayfayı kapatabilirsiniz.'
                });
                setTimeout(() => {
                    this.props.history.push('/login');
                }, 100);
            })
            .catch(err => ShowErrorNotification('Kullanıcı çıkışı yapılamadı', err))
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        Authentication.getCurrentUser()
            .then(user => this.setState({user}))
            .catch(err => {
                if (err !== 'not authenticated') {
                    console.log('No current user.');
                } else {
                    ShowErrorNotification('Kullanıcı bilgileri alınamadı', err);
                }
            });
    }

    render() {
        const {path} = this.props.match;
        const keys = path.split('/');
        const lastKey = keys.pop();
        let defaultSelectedKeys = undefined;
        if (lastKey === ':id') {
            defaultSelectedKeys = keys.pop();
        } else {
            defaultSelectedKeys = lastKey;
        }
        return (
            <Layout className="default-layout">
                <Layout.Header>
                    <Menu
                        mode="horizontal"
                        selectable="false"
                        style={{
                            lineHeight: '64px',
                            borderBottomWidth: '0px!important'
                        }}
                    >
                        <Menu.Item>
                            <Link to="/" onClick={e => e.stopPropagation()}>
                                <img style={{height: 48}} src={require('../assets/images/logo_header.png')} alt="logo"/>
                            </Link>
                        </Menu.Item>
                        {!this.state.user && this.props.match.path !== '/login' && (
                            <Menu.Item style={{float: 'right'}}>
                                <Link to="/login">
                                    <Icon type="login"/>
                                    Kullanıcı Girişi
                                </Link>
                            </Menu.Item>
                        )}
                        {this.state.user && (
                            <Menu.SubMenu
                                style={{float: 'right'}}
                                title={(
                                    <div>
                                        {this.state.user.name || this.state.user.email}&nbsp;
                                        <Icon type="down"/>
                                    </div>
                                )}
                            >
                                <Menu.Item>
                                    <Link to="/profile">
                                        <Icon type="user"/>
                                        Hesabım
                                    </Link>
                                </Menu.Item>
                                <Menu.Item onClick={this.logout}>
                                    <Icon type="logout"/>
                                    Oturumu Kapat
                                </Menu.Item>
                            </Menu.SubMenu>

                        )}
                    </Menu>
                </Layout.Header>
                <Layout>
                    <Layout.Sider width={200}>
                        <Menu
                            mode="inline"
                            theme="dark"
                            defaultSelectedKeys={defaultSelectedKeys}
                            defaultOpenKeys={["plans","registrations","settings"]}
                            style={{ height: '100%', borderRight: 0 }}
                            onClick={this.handleClick}
                        >
                            <Menu.Item key="plans">
                                <Icon type="calendar"/>
                                Ders Planları
                            </Menu.Item>
                            <Menu.Item key="registrations">
                                <Icon type="laptop"/>
                                Öğrenci Kayıtları
                            </Menu.Item>
                            <Menu.SubMenu
                                key="settings"
                                title={(
                                    <span>
                                        <Icon type="setting"/>
                                        Ayarlar
                                    </span>
                                )}>
                                <Menu.Item key="periods">Yıllar</Menu.Item>
                                <Menu.Item key="schools">Okullar</Menu.Item>
                                <Menu.Item key="users">Kullanıcılar</Menu.Item>
                                <Menu.Item key="system">Sistem Ayarları</Menu.Item>
                            </Menu.SubMenu>
                            <Menu.Item key="profile">
                                <Icon type="user"/>
                                Hesabım
                            </Menu.Item>
                            <Menu.Item key="logout" onClick={this.logout}>
                                <Icon type="logout"/>
                                Oturumu Kapat
                            </Menu.Item>
                        </Menu>
                    </Layout.Sider>
                    <Layout>
                        <Layout.Content>
                            {this.props.children}
                        </Layout.Content>
                        {/*<Layout.Footer>*/}
                        {/*    <Col align="center">*/}
                        {/*        Made with <span role='img'>❤</span>️ by <a href="http://www.asyasoft.net" target="_blank" rel="noopener noreferrer">Asyasoft</a>*/}
                        {/*    </Col>*/}
                        {/*</Layout.Footer>*/}
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}

export default withRouter(AuthenticatedLayout);
