import React, {Component} from 'react';
import {Icon, Select} from "antd";
import {ShowErrorNotification} from '../utils';
import data from '../data';

class PeriodSelect extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    loadData() {
        this.setState({loading: true});
        return Promise.all([
            data.allPeriods(),
            data.getSetting("CURRENT_PERIOD").catch(reason => undefined)
        ])
            .then(([periods, defaultPeriod]) => {
                this.setState({
                    periods,
                    value: defaultPeriod?.value
                }, () => {
                    this.handleChange(this.state.value);
                });
            })
            .catch(err => ShowErrorNotification('Yıllar alınamadı', err))
            .finally(() => this.setState({loading: false}));
    }

    handleChange(value) {
        this.setState({value}, () => {
            if (this.props.onChange) {
                this.props.onChange(value);
            }
        })
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <Select
                {...this.props}
                style={{minWidth: 150}}
                value={this.state.value}
                loading={this.state.loading}
                onChange={this.handleChange}>
                {this.state.periods?.map(period => (
                    <Select.Option
                        key={period.id}
                        value={period.id}
                    >
                        <Icon type={period.enabled ? "check-circle" : "close-circle"}
                              style={{color: period.enabled ? "green" : "red"}}/>
                        <span className="margin-left-10">
                            {period.name}
                        </span>
                    </Select.Option>
                ))}
            </Select>
        );
    }
}

export default PeriodSelect;
