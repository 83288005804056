import React, {Component} from "react";
import {Redirect, Route, withRouter} from "react-router-dom";
import {Authentication} from "../../utils";
import {Icon} from "antd";

export default withRouter(
    class ProtectedRoute extends Component {
        state = {
            loading: true,
            authenticated: false
        };

        componentDidMount() {
            this.setState({loading: true});
            Authentication.getCurrentSession()
                .then(session => this.setState({authenticated: true}))
                .catch(err => {
                    console.log("Error getting current session.", err);
                    this.setState({authenticated: false});
                })
                .finally(() => this.setState({loading: false}));
        }

        render() {
            const {loading, authenticated} = this.state;
            if (loading) {
                return (
                    <div style={{margin: 10}}>
                        <Icon spin type="sync" style={{marginRight: 5}}/>
                        Lütfen bekleyin...
                    </div>
                )
            } else if (authenticated) {
                return <Route {...this.props} />
            } else {
                const {location} = this.props;
                return <Redirect to={`/login?returnUrl=${location.pathname}${location.search}`}/>
            }
        }
    }
)
