export default {
    api: {
        url: "https://atd8aq8ym8.execute-api.eu-central-1.amazonaws.com/production"
    },
    authentication: {
        region: 'eu-central-1',
        poolId: 'eu-central-1_pqmwwty8a',
        clientId: '7nvbrm7hkab1qjlcv00eca3759'
    }
};
