import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import './App.less';
import * as Pages from './pages';
import {ProtectedRoute} from './utils';

class App extends Component {
    render() {
        return (
            <div className="App">
                {/*<div style={{*/}
                {/*    color: 'white',*/}
                {/*    backgroundColor: 'red',*/}
                {/*    padding: 8,*/}
                {/*    textAlign: 'center',*/}
                {/*}}>*/}
                {/*    Bu uygulama test aşamasındadır. Girdiğiniz veriler canlıya geçmeden önce silinecektir.*/}
                {/*</div>*/}
                <Router>
                    <Switch>
                        <Route exact path="/login" component={Pages.LoginPage}/>
                        <ProtectedRoute exact path="/" component={Pages.HomePage}/>
                        <ProtectedRoute exact path="/plans" component={Pages.PlansPage}/>
                        <ProtectedRoute path="/plans/:id" component={Pages.PlanDetailsPage}/>
                        <ProtectedRoute exact path="/registrations" component={Pages.RegistrationsPage}/>
                        <ProtectedRoute exact path="/settings/periods" component={Pages.PeriodsPage}/>
                        <ProtectedRoute exact path="/settings/schools" component={Pages.SchoolsPage}/>
                        <ProtectedRoute exact path="/settings/users" component={Pages.UsersPage}/>
                        <ProtectedRoute exact path="/settings/system" component={Pages.SettingsPage}/>
                        <ProtectedRoute exact path="/profile" component={Pages.ProfilePage}/>
                        <Route exact path="/forgot-password" component={Pages.ForgotPasswordPage}/>
                        <Route exact path="/reset-password" component={Pages.ResetPasswordPage}/>
                        <Pages.NotFoundPage/>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;
