import React, {Component} from 'react';
import {Button, Checkbox, Col, Form, Input, Modal, Row} from "antd";
import {ShowErrorNotification, ShowValidationErrorNotification} from '../../utils';
import data from "../../data";

class EditModal extends Component {
    state = {
        loading: false,
        visible: false,
    };

    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    showModal(record) {
        this.setState({
            record,
            loading: false,
            visible: true
        });
    };

    closeModal(record) {
        this.setState({visible: false}, () => {
            if (this.props.onClose) {
                this.props.onClose(record);
            }
        })
    };

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification();
            }
            this.setState({loading: true});
            data.updateRegistration(this.state.record.id, values)
                .then(this.closeModal)
                .catch(err => ShowErrorNotification('Öğrenci ders kaydı kaydedilemedi', err))
                .finally(() => this.setState({loading: false}));
        });
    };

    handleCancel() {
        this.closeModal();
    };

    renderForm() {
        const {getFieldDecorator} = this.props.form;
        const {record} = this.state;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="Veli Ad, Soyad">
                            <Input disabled value={record?.parent_name} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Email">
                            <Input disabled value={record?.email} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Telefon">
                            <Input disabled value={record?.phone_number} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="Öğrenci Ad, Soyad">
                            <Input disabled value={record?.student_name}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Eğitim-Öğretim Yılı">
                            <Input disabled value={record?.period_name} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Sınıf">
                            <Input disabled value={record?.class_name} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Okul">
                            <Input disabled value={record?.school_name} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        {getFieldDecorator('enabled', {
                            initialValue: record?.enabled,
                            valuePropName: 'checked',
                        })(<Checkbox>Aktif</Checkbox>)}
                    </Col>
                </Row>
            </Form>
        );
    }

    componentDidMount() {
        if (this.props.visible) {
            this.showModal();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visible !== this.props.visible && this.props.visible) {
            this.showModal(this.props.record);
        }
    }

    render() {
        return (
            <Modal
                visible={this.state.visible}
                title="Öğrenci Ders Kaydı Düzenle"
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                    <a
                        key="download"
                        href={this.state.record?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button icon="file-pdf" style={{float: 'left'}}>
                            PDF Dosyasını İndir
                        </Button>
                    </a>,
                    <Button
                        key="back"
                        onClick={this.handleCancel}
                    >
                        Kapat
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        icon={this.state.loading ? 'loading' : 'check'}
                        onClick={this.handleSubmit}
                    >
                        Kaydet
                    </Button>
                ]}
                destroyOnClose={true}
            >
                {this.renderForm()}
            </Modal>
        );
    }
}

export default Form.create()(EditModal);
