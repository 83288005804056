import * as API from './api';

class DataProvider {
    /**
     * Periods
     */
    allPeriods(filter) {
        return API.PeriodsService.all(filter);
    }
    listPeriods(filter) {
        return API.PeriodsService.list(filter);
    }
    getPeriod(id) {
        return API.PeriodsService.get(id);
    }
    createPeriod(data) {
        return API.PeriodsService.create(data);
    }
    updatePeriod(id, data) {
        return API.PeriodsService.update(id, data);
    }
    deletePeriod(id) {
        return API.PeriodsService.delete(id);
    }
    enablePeriod(id) {
        return API.PeriodsService.enable(id);
    }
    disablePeriod(id) {
        return API.PeriodsService.disable(id);
    }

    /**
     * Plans
     */
    allPlans(filter) {
        return API.PlansService.all(filter);
    }
    listPlans(filter) {
        return API.PlansService.list(filter);
    }
    getPlan(id) {
        return API.PlansService.get(id);
    }
    createPlan(data) {
        return API.PlansService.create(data);
    }
    updatePlan(id, data) {
        return API.PlansService.update(id, data);
    }
    deletePlan(id) {
        return API.PlansService.delete(id);
    }
    enablePlan(id) {
        return API.PlansService.enable(id);
    }
    disablePlan(id) {
        return API.PlansService.disable(id);
    }
    copyPlan(id, data) {
        return API.PlansService.copy(id, data);
    }

    /**
     * Plan Details
     */
    allPlanDetails(filter) {
        return API.PlanDetailsService.all(filter);
    }
    listPlanDetails(filter) {
        return API.PlanDetailsService.list(filter);
    }
    getPlanDetail(id) {
        return API.PlanDetailsService.get(id);
    }
    createPlanDetail(data) {
        return API.PlanDetailsService.create(data);
    }
    updatePlanDetail(id, data) {
        return API.PlanDetailsService.update(id, data);
    }
    deletePlanDetail(id) {
        return API.PlanDetailsService.delete(id);
    }

    /**
     * Registrations
     */
    allRegistrations(filter) {
        return API.RegistrationsService.all(filter);
    }
    listRegistrations(filter) {
        return API.RegistrationsService.list(filter);
    }
    getRegistration(id) {
        return API.RegistrationsService.get(id);
    }
    createRegistration(data) {
        return API.RegistrationsService.create(data);
    }
    updateRegistration(id, data) {
        return API.RegistrationsService.update(id, data);
    }
    deleteRegistration(id) {
        return API.RegistrationsService.delete(id);
    }
    enableRegistration(id) {
        return API.RegistrationsService.enable(id);
    }
    disableRegistration(id) {
        return API.RegistrationsService.disable(id);
    }
    downloadRegistrations(periodId) {
        return API.RegistrationsService.download(periodId);
    }

    /**
     * Schools
     */
    allSchools(filter) {
        return API.SchoolsService.all(filter);
    }
    listSchools(filter) {
        return API.SchoolsService.list(filter);
    }
    getSchool(id) {
        return API.SchoolsService.get(id);
    }
    createSchool(data) {
        return API.SchoolsService.create(data);
    }
    updateSchool(id, data) {
        return API.SchoolsService.update(id, data);
    }
    deleteSchool(id) {
        return API.SchoolsService.delete(id);
    }
    enableSchool(id) {
        return API.SchoolsService.enable(id);
    }
    disableSchool(id) {
        return API.SchoolsService.disable(id);
    }

    /**
     * Users
     */
    allUsers(filter) {
        return API.UsersService.all(filter);
    }
    listUsers(filter) {
        return API.UsersService.list(filter);
    }
    getUser(id) {
        return API.UsersService.get(id);
    }
    createUser(data) {
        return API.UsersService.create(data);
    }
    updateUser(id, data) {
        return API.UsersService.update(id, data);
    }
    deleteUser(id) {
        return API.UsersService.delete(id);
    }
    enableUser(id) {
        return API.UsersService.enable(id);
    }
    disableUser(id) {
        return API.UsersService.disable(id);
    }

    /**
     * Settings
     */
    allSettings(filter) {
        return API.SettingsService.all(filter);
    }
    getSetting(key) {
        return API.SettingsService.get(key);
    }
    updateSettings(data) {
        return API.SettingsService.update(data);
    }
}

export default new DataProvider();
