import config from "../../config";
import {Authentication} from "../../utils";
import axios from "axios";

class AxiosHelper {
    list(url, data) {
        return this.sendRequest("GET", url, data)
        // .then(result => {
        //     const {items, ...paging} = result;
        //     return {items, paging};
        // });
    }
    all(url, data) {
        return this.sendRequest("GET", `${url}/all`, data);
    }
    get(url, data) {
        return this.sendRequest("GET", url, data);
    }
    post(url, data) {
        return this.sendRequest("POST", url, data);
    }
    put(url, data) {
        return this.sendRequest("PUT", url, data);
    }
    delete(url, data) {
        return this.sendRequest("DELETE", url, data);
    }
    sendRequest(method, url, data) {
        // console.log("Request", method, url, data);
        // return Promise.resolve("JwtToken")
        return Authentication.getCurrentSession()
            .then(session => session.getIdToken().getJwtToken())
            .then(token => {
                const endpoint = `${config.api.url}/${url}`;
                const headers = {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                };
                switch (method.toUpperCase()) {
                    case "GET":
                        return axios.get(endpoint, {...data, headers});
                    case "POST":
                        return axios.post(endpoint, data, {headers});
                    case "PUT":
                        return axios.put(endpoint, data, {headers});
                    case "DELETE":
                        return axios.delete(endpoint, {data, headers});
                    default:
                        throw new Error(`Unexpected API method type: "${method}"`);
                }
            })
            .then(res => {
                // console.log("Response", res);
                // if (res.data?.items) {
                //     const {items, ...rest} = res.data;
                //     console.table(items);
                //     console.log(rest);
                // } else if (Array.isArray(res.data)) {
                //     console.table(res.data);
                // } else {
                //     console.log(res.data);
                // }
                return res.data;
            });
    }
}

export default new AxiosHelper();
