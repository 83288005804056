import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Button, Col, Form, Input, notification, Row} from 'antd';
import {DefaultLayout} from "../../layouts";
import {Container} from "../../components";
import {Authentication, ShowErrorNotification, ShowValidationErrorNotification} from '../../utils';

class ForgotPasswordPage extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification(err);
            }
            const {email} = values;
            this.setState({submitting: true});
            Authentication.forgotPassword(email)
                .then(() => {
                    notification.success({
                        message: 'Email Gönderildi',
                        description: 'Lütfen emailinizi kontrol edip gönderdiğimiz bağlantıya tıklayarak yeni şifre alın.'
                    });
                    setTimeout(() => {
                        this.props.history.push(`/reset-password?email=${email}`);
                    }, 100);
                })
                .catch(err => ShowErrorNotification('Şifre hatırlatma kaydı alınamamdı', err))
                .finally(() => this.setState({submitting: false}));
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <DefaultLayout>
                <Container size="small">
                    <div className="page">
                        <h1 className="page-header">Şifremi Unuttum</h1>
                        <Form onSubmit={this.handleSubmit}>
                            <Row gutter={24}>
                                <Col>
                                    <Form.Item label="Email Adresi">
                                        {getFieldDecorator('email', {
                                            rules: [
                                                {required: true, message: 'Email adresi girilmemiş'},
                                                {type: 'email', message: 'Geçerli bir email adresi değil'}
                                            ]
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br/>
                            <Button
                                loading={this.state.submitting}
                                type="primary"
                                htmlType="submit"
                                icon="check"
                            >
                                Gönder
                            </Button>
                        </Form>
                    </div>
                </Container>
            </DefaultLayout>
        );
    }
}

export default Form.create()(withRouter(ForgotPasswordPage));
