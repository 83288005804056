import React, {Component} from 'react';
import {Button, Checkbox, Form, Input, Modal} from "antd";
import {ShowErrorNotification, ShowValidationErrorNotification} from '../../utils';
import data from "../../data";
import {PeriodSelect} from "../../components";

class CopyModal extends Component {
    state = {
        loading: false,
        visible: false,
    };

    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }


    showModal() {
        this.setState({
            loading: false,
            visible: true
        });
    }

    closeModal(record) {
        this.setState({visible: false}, () => {
            if (this.props.onClose) {
                this.props.onClose(record);
            }
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return ShowValidationErrorNotification();
            }
            this.setState({loading: true});
            data.copyPlan(this.props.record.id, values)
                .then(this.closeModal)
                .catch(err => ShowErrorNotification('Ders planı kopyalamadı', err))
                .finally(() => this.setState({loading: false}));
        });
    }

    handleCancel() {
        this.closeModal();
    }

    renderForm() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item label="Eğitim-Öğretim Yılı">
                    {getFieldDecorator('period_id', {
                        rules: [{required: true, message: 'Eğitim-öğretim yılı seçilmemiş'}]
                    })(<PeriodSelect autoFocus placeholder="Yıl seçin"/>)}
                </Form.Item>
                <Form.Item label="Ad (Türkçe)">
                    {getFieldDecorator('name_tr', {
                        initialValue: this.props.record?.name_tr,
                        rules: [{required: true, message: 'Türkçe adı girilmemiş'}]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Ad (Almanca)">
                    {getFieldDecorator('name_de', {
                        initialValue: this.props.record?.name_de,
                        rules: [{required: true, message: 'Almanca adı girilmemiş'}]
                    })(<Input />)}
                </Form.Item>
                {getFieldDecorator('enabled', {
                    initialValue: true,
                    valuePropName: 'checked',
                })(<Checkbox>Aktif</Checkbox>)}
                {getFieldDecorator('published', {
                    initialValue: false,
                    valuePropName: 'published',
                })(<Checkbox>Yayında</Checkbox>)}
            </Form>
        );
    }

    componentDidMount() {
        if (this.props.visible) {
            this.showModal();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visible !== this.props.visible && this.props.visible) {
            this.showModal();
        }
    }

    render() {
        return (
            <Modal
                visible={this.state.visible}
                title="Ders Planı Kopyala"
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                footer={[
                    <Button
                        key="back"
                        onClick={this.handleCancel}>
                        Kapat
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        icon="check"
                        loading={this.state.loading}
                        onClick={this.handleSubmit}
                    >
                        Kopyala
                    </Button>
                ]}
                destroyOnClose={true}
            >
                {this.renderForm()}
            </Modal>
        );
    }
}

export default Form.create()(CopyModal);
