import {GenericAPI} from "./generic-api-client";
import {API} from "../../utils/api";

export default GenericAPI("registrations", path => {
    return {
        download(periodId) {
            return API.get(`${path}/download/${periodId}`);
        }
    }
});
