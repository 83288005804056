import {GenericAPI} from "./generic-api-client";
import {API} from "../../utils/api";

export default GenericAPI("settings", path => {
    return {
        update(data) {
            return API.put(path, data);
        }
    }
});
